import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@mui/material';
import { Typography } from '@northwell-health/nw-component-lib';

const lowScore = () => {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: `${isMobile ? '350px' : ''}`,
        }}
      >
        <Typography
          type="body"
          size={isMobile ? 'small' : 'large'}
          weight="light"
        >
          We appreciate you taking the time to prioritize your health. We look
          forward to seeing you at your next visit.
        </Typography>
      </Grid>
    </Grid>
  );
};
export default lowScore;
