import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import SignaturePad from 'react-signature-canvas';
import "../formcomponents/ElectronicSignature.css";
import { Grid, Box, Paper } from '@mui/material';
import { Typography, Button } from '@northwell-health/nw-component-lib'
import styled from '@emotion/styled';
import { useProgress } from "../context/ProgressContext";

const Item = styled(Paper)(({ theme }) => ({
 // ...theme.typography.body2,
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
}));

const styles = {
  signatureSectionCont: {
    padding: '10px'
  },
  errorMsgCont:{
    display: 'block',
    clear: 'both',
    width: '100%',
    paddingTop: '40px'
  }
};

const minorPageThree = (props) => {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();
  // Set Value For Progress
  updateProgress(80); 

  const { SubmissionState, onFormStateChange } = props;
  const { register, handleSubmit, setValue, formState: {}} = useForm();
  const [ isSigned, setIsSigned ] = useState(false);
  const [imageURL, setImageURL] = useState(null); 
  const signData = useRef({});
  
  const setCardColor =function(color){
    let SigCardSt = document.getElementsByClassName("Card");
    let SigPadBk = document.getElementsByClassName("SigPad");
    SigPadBk[0].style.backgroundColor = color;
    SigCardSt[0].style.backgroundColor = color;
  }
  
  const clear = () => {
    setImageURL("reset");
    setIsSigned(false),    
    signData.current.clear(),
    setCardColor("#FAFAFA")
    };

  const save = () => setImageURL(   
    signData.current.getTrimmedCanvas().toDataURL("image/png"),
    setValue("imageURLStr", "True"),
    setCardColor("#E8F0FE"),
    setIsSigned(true)
    );
    
  const saveifNotEmpty = () => {
    if(signData.current.isEmpty()){
      alert('You must sign document'); 
    }else{
      save();
    }
  };
  
  const handleRegistration = (data) => {
    if(imageURL)  {
      setValue("imageURLStr", "True");
      setIsSigned(true);
    }
    
    data.imageUrl = imageURL;
    data.uploadFiles = "See Folder Images";
    SubmissionState.sec = data;
    SubmissionState.sectionThreeForm = data;
    SubmissionState.sectionThreSubmitted = true ;    
    SubmissionState.goBackPageOne = false;
    SubmissionState.goBackPageTwo = false;
    SubmissionState.goBackLogin = false;    
    SubmissionState.submitCompletedForm = true;
    onFormStateChange();
  };

  const previousPageClick = () => {
    SubmissionState.showPageThree = false;
    SubmissionState.showPageTwo = true;
    SubmissionState.goBackPageTwo = true;
    SubmissionState.goPageThree = false;
    onFormStateChange();
  };

  const scrolltoTop = function() {
    window.scrollTo(0, 0);
  };

  scrolltoTop();
  
  return (  
    <Box className="pageContainer">
      <Grid container
        spacing={1}
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
              <p className="breadcrumb_nav" onClick={previousPageClick}>&#60; Your Information</p>
        </Grid>
        <Grid item xs={12} s={6} md={6}>
          <Item elevation={0}>
                <Typography variant="h1" style={{ marginBottom: 24 }}>Patient authorization</Typography>
            </Item>
            <Item elevation={0}>
                <ul>
                  <li>I authorize Northwell Health to disclose the Child&rsquo;s protected health information (PHI) to the FollowMyHealth&trade; Patient Portal. This information includes, but is not limited to: health summary, current problem list, current medications, lab results, appointment information. This may also include, and I specifically authorize release of, information relating to 1) Acquired immunodeficiency syndrome (AIDS), or human immunodeficiency virus (HIV) infection, 2) treatment for drug or alcohol abuse, 3) sexually transmitted diseases or 4) mental or behavioral health or psychiatric care.                    
                  </li>
                  <li>I understand that this authorization will be in effect until such time as it has been revoked, which may be done by contacting the FMH Support line at 844-364-8108 or by writing to the provider at the address below. Such revocation shall be effective except to the extent that action has already been taken based on this authorization.                    
                  </li>
                  <li>I understand that signing this authorization is voluntary. My child&rsquo;s treatment will not be conditioned upon my authorization of this disclosure.                    
                  </li>
                  <li>Information disclosed under this authorization might be re-disclosed by the recipient, and this redisclosure may no longer be protected by federal or state law.                    
                  </li>
                </ul>
              </Item>
            </Grid>
            <Grid item xs={12} s={6} md={6}>
          <Item elevation={0}>
            <Typography variant="h1" style={{ marginBottom: 24 }}>Proxy designation</Typography>
            </Item>
            <Item elevation={0}>
            <ul>
              <li>I am the parent or legal guardian of this Child.</li>
              <li>There are no court orders or restraining orders in effect limiting my access to this Child&rsquo;s medical records and/or information.</li>
              <li>I understand that I may cancel this designation at any time by contacting the FMH Support line at 844-364-8108.</li>
              <li> I will establish my own FollowMyHealth account in order to access the Child&rsquo;s FollowMyHealth Personal Health Record (PHR) account.</li>
              <li>I will be granted full access to the Child&rsquo;s FollowMyHealth Personal Health Record (PHR) for the Child until his/her 13th birthday at which time I will no longer receive updates to the Child&rsquo;s FollowMyHealth Personal Record.</li>
            </ul>
            </Item>
          </Grid>
          <Grid item xs={12}><br/></Grid>
<Grid item xs={12}><br/></Grid>          
    <Grid item xs={12} style={{ marginBottom: 24 }}>
      <Typography><strong>
I hereby certify that I am the parent or legal guardian of the patient listed on this form and have the right to access their health information.
</strong>
      </Typography>
    </Grid>
  <form onSubmit={handleSubmit(handleRegistration)}>
      <Grid item xs={12}>
      <Item elevation={0}>
          <div className="Card">
          <h2>Signature Required *</h2>
          <div className="SigContainer">
            <SignaturePad penColor="black" canvasProps={{ className: "SigPad" }}  ref={signData} />
            <div>
              {!isSigned? <p className="signatureError">A Signature Is Required. Please sign and click "SAVE" to submit.</p>: null}
              </div>
              <div>
                  <div className="blockDis">
                    <Button  size="small" text="Reset Signature" color="primary" onClick={clear}  type="button"/>
                  </div>
                  <div className="blockDis">
                    <Button size="small" text="Save" color="primary" onClick={saveifNotEmpty}  type="submit" />
                  </div>
                </div>           
            </div>
          </div>
          <input {...register("imageURLStr", { required: true })} type="hidden" />          
          <div style={styles.errorMsgCont}></div>
          </Item>
          </Grid>
      </form>
    </Grid>
  </Box>
  );
};
export default minorPageThree;
