// Set PHQ2 Form questions.
export const PHQ2 = {
  questions: [
    {
      id: 'questionOne',
      value: 'Little interest or pleasure in doing things:',
    },
    {
      id: 'questionTwo',
      value: 'Feeling down, depressed or hopeless:',
    },
  ],
  answers: [
    {
      id: 'not-at-all',
      textValue: 'Not at all',
      numValue: '0',
    },
    {
      id: 'several-days',
      textValue: 'Several days ',
      numValue: '1',
    },
    {
      id: 'more-than-half-the-days',
      textValue: 'More than half the days',
      numValue: '2',
    },
    {
      id: 'nearly-everyday',
      textValue: 'Nearly everyday',
      numValue: '3',
    },
  ],
};
