import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid, Box } from '@mui/material';
import {
  Button,
  Typography,
  InlineAlert,
  Preloader,
} from '@northwell-health/nw-component-lib';
import { formatPHQ2Results } from '../../utils/helpers';
import * as touchworksService from '../../services/phq2/touchworks';
import OptOutPage from './opt-out';
import { useProgress } from "../context/ProgressContext";

const pageTitle = 'Patient health questionnaire form';

const InformationPage = (props) => {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    onFormStateChange,
    patientIdentifier,
    token,
    providerID,
    encounterID,
    touchworksID,
  } = props;

  // Set loading state.
  const [loading, setLoading] = useState(false);
  // Set error alert state.
  const [errorAlert, setErrorAlert] = useState(false);
  // Set error alert message string.
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [optOutPageShow, showOptOutPage] = useState(false);

  const nextPage = () => {
    onFormStateChange();
  };

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;
    document.getElementById('pageTextHeader').innerHTML = pageTitle;
    // Set Value For Progress
    updateProgress(50); 
  }, []);

  // Handle opting out of the form.
  const handleOptOut = async () => {
    try {
      setLoading(true);
      // Check for required params.
      if (
        !patientIdentifier ||
        !token ||
        !providerID ||
        !encounterID ||
        !touchworksID
      ) {
        throw 'Patient Identifier, Token, Provider Id, Encounter ID, or Internal Patient ID missing';
      }
      // Format PHQ2 results.
      const formattedResults = formatPHQ2Results(
        patientIdentifier,
        providerID,
        encounterID,
        'Declined',
        'Declined',
        'Declined',
      );
      // Post results to Touchworks.
      const results = await touchworksService.saveMultipleResults(
        patientIdentifier,
        token,
        formattedResults,
      );
      // Check for failure.
      if (!results || results !== 'Success') {
        throw 'Something went wrong!';
      }
      // Retire the token.
      await touchworksService.retireTouchworksToken(token);
      setLoading(false);
      showOptOutPage(true);
    } catch (e) {
      setLoading(false);
      // Set Error message
      setErrorAlertMessage('We ran into an issue. Please try again');
      //Keep error alert true for 5 seconds.
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 5000);
    }
  };

  // Show loading screen.
  if (loading) {
    return <Preloader fullScreen text="Loading ..." />;
  }

  // Show opt out page.
  if (optOutPageShow) {
    return <OptOutPage />;
  }

  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        {/** In line error alert */}
        {errorAlert && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: `${isMobile ? '40px' : '80px'}`,
            }}
          >
            <InlineAlert severity="error" text={errorAlertMessage} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            type="h1"
            gutterBottom={true}
            style={{
              marginTop: `${isMobile ? '40px' : '80px'}`,
              marginBottom: 24,
            }}
          >
            Overview
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: `${isMobile ? '' : '75%'}` }}>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography
              type="body"
              size={isMobile ? 'small' : 'large'}
              weight="light"
            >
              Northwell stresses the importance of depression screening for all
              patients annually. We ask these questions because it's
              important-and because we can help.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography
              type="body"
              size={isMobile ? 'small' : 'large'}
              weight="light"
            >
              Please be advised that your provider won't be reviewing your
              answers from this questionnaire before or during your appointment,
              but you will receive information and resources if needed upon
              completion of this survey. If you need immediate care, please go
              to your nearest emergency room or call 911.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={'16px'}>
          <Grid item>
            <Button
              text="Continue"
              color="primary"
              size="large"
              type="submit"
              onClick={nextPage}
            />
          </Grid>
          <Grid item>
            <Button
              text="Decline to answer"
              color="secondary"
              size="large"
              type="submit"
              onClick={handleOptOut}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default InformationPage;
