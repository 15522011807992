import React, { createContext, useContext, useState } from "react";

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [progressValue, setProgressValue] = useState(0); // Initial value

  const updateProgress = (percentage) => {
    // Ensure percentage stays between 0 and 100
    setProgressValue(Math.min(100, Math.max(0, percentage)));
  };

  return (
    <ProgressContext.Provider value={{ progressValue, updateProgress }}>
      {children}
    </ProgressContext.Provider>
  );
};

// Custom hook for consuming the context
export const useProgress = () => useContext(ProgressContext);
