import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import { Typography } from '@northwell-health/nw-component-lib';
import { useProgress } from '../context/ProgressContext';

const OptOutPage = () => {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    // Set Value For Progress
    updateProgress(100);
  }, []);

  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item xs={12}>
          <Typography
            type="h1"
            style={{
              marginTop: `${isMobile ? '40px' : '80px'}`,
              paddingBottom: `${isMobile ? '350px' : ''}`,
            }}
          >
            You have chosen to opt out of taking the Patient Health
            Questionnaire at this time.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OptOutPage;
